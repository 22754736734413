import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['source']
  static values = {
    text: String,
    successDuration: { type: Number, default: 2000 },
    successClass: { type: String, default: '--copied' }
  }

  copy () {
    let text
    if (this.hasTextValue) {
      text = this.textValue
    } else if (this.hasSourceTarget) {
      text = this.sourceTarget.value || this.sourceTarget.innerHTML
    }

    if (text) {
      navigator.clipboard.writeText(text).then(() => this._copied())
    }
  }

  _copied () {
    if (this.timeout) clearTimeout(this.timeout)

    this.element.classList.add(this.successClassValue)

    this.timeout = setTimeout(() => {
      this.element.classList.remove(this.successClassValue)
    }, this.successDurationValue)
  }
}
