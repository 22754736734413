import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    formId: String,
    fieldId: String,
    input: String,
    submitAfterFormUpdate: { type: Boolean, default: false }
  }

  perform () {
    const form = document.querySelector(`#${this.formIdValue}`)
    const hiddenInput = form.querySelector(`#${this.fieldIdValue}`)
    hiddenInput.value = this.inputValue

    if (this.submitAfterFormUpdateValue) {
      form.requestSubmit()
    }
  }
}
