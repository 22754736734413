import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['header', 'sidebar', 'sidebarToggle', 'main']

  sidebarStateKey = 'app-shell-sidebar-default'

  connect () {
    if (!this.hasSidebarTarget || !this.hasMainTarget) {
      return
    }

    this.initSidebar()

    window.addEventListener('resize', () => {
      this.windowResized()
    })
  }

  initSidebar () {
    // eslint-disable-next-line no-undef
    const sidebarDefaultState = sessionStorage.getItem(this.sidebarStateKey)
    this.disableSidebarTransition()

    if (sidebarDefaultState === 'open') {
      this.openSidebar()
    } else if (sidebarDefaultState === 'closed') {
      this.closeSidebar()
    }
  }

  isSidebarInOverlayMode () {
    return window.innerWidth < 992
  }

  isSidebarOpen () {
    // eslint-disable-next-line no-undef
    return getComputedStyle(this.sidebarTarget).left === '0px'
  }

  windowResized (_event) {
    if (this.isSidebarOpen() && this.isSidebarInOverlayMode()) {
      this.sidebarTarget.classList.remove('sidebar-open')
      this.mainTarget.classList.remove('sidebar-open')

      // eslint-disable-next-line no-undef
      sessionStorage.removeItem(this.sidebarStateKey)
    }
  }

  enableSidebarTransition () {
    this.sidebarTarget.classList.remove('disable-sidebar-transition')
    this.mainTarget.classList.remove('disable-sidebar-transition')
  }

  disableSidebarTransition () {
    this.sidebarTarget.classList.add('disable-sidebar-transition')
    this.mainTarget.classList.add('disable-sidebar-transition')
  }

  openSidebar () {
    this.sidebarTarget.classList.remove('sidebar-closed')
    this.mainTarget.classList.remove('sidebar-closed')
    this.sidebarTarget.classList.add('sidebar-open')
    this.mainTarget.classList.add('sidebar-open')

    // eslint-disable-next-line no-undef
    sessionStorage.setItem(this.sidebarStateKey, 'open')
  }

  closeSidebar () {
    this.sidebarTarget.classList.remove('sidebar-open')
    this.mainTarget.classList.remove('sidebar-open')
    this.sidebarTarget.classList.add('sidebar-closed')
    this.mainTarget.classList.add('sidebar-closed')

    // eslint-disable-next-line no-undef
    sessionStorage.setItem(this.sidebarStateKey, 'closed')
  }

  toggleSidebar () {
    this.enableSidebarTransition()
    this.isSidebarOpen() ? this.closeSidebar() : this.openSidebar()
  }

  appMainContainerClicked (evt) {
    if (this.isSidebarInOverlayMode() && this.isSidebarOpen()) {
      evt.preventDefault()
      this.enableSidebarTransition()
      this.closeSidebar()
    }
  }
}
